import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './footer.css';
import { Link } from '@reach/router';
import { shallowEqual, useSelector } from 'react-redux';
import LogoLNEXT from '../images/logoLNext-edit-old-4nd.png';

const Footer = (role) => {
  const isLoggedInDriver = useSelector(
    (state: any) => state.authDriver.isLoggedIn,
    shallowEqual
  );
  const isLoggedInCustomer = useSelector(
    (state: any) => state.authCustomer.isLoggedIn,
    shallowEqual
  );

  let contactLink = '/#contact';
  if (isLoggedInCustomer) {
    contactLink = '/customer/contact';
  } else if (isLoggedInDriver) {
    contactLink = '/member/contact';
  }

  const openTerms = () => {
    const file = './pdf/manual.pdf';
    window.open(file);
  };

  return (
    <Container className="footer-container" fluid>
      <Row>
        <Col xs={5} sm={5} className="footer-logo">
          <Link to="/">
            <img src={LogoLNEXT} alt="Logo L-Next" />
          </Link>
        </Col>
        <Col xs={2} sm={2} />
        <Col xs={5} sm={5} className="footer-menu">
          <ul>
            <li>
              <a target="_blank" href="/terms.pdf">
                利用規約
              </a>
            </li>
            <li>
              <Link to={contactLink}>お問い合わせ</Link>
            </li>
            <li>
              <Link to="/company">運営者情報</Link>
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="footer-copyright">
        <Col>
          <span>©{new Date().getFullYear()} 一般社団法人中小企業物流協会</span>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
